import { lazy, Suspense, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
import CompactLayout from 'src/layouts/compact';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

export const HomePage = lazy(() => import('src/pages/home'));
const Page500 = lazy(() => import('src/pages/500'));
const Page403 = lazy(() => import('src/pages/403'));
const Page404 = lazy(() => import('src/pages/404'));
const FaqsPage = lazy(() => import('src/pages/faqs'));
const ContactPage = lazy(() => import('src/pages/contact-us'));
const PricingPage = lazy(() => import('src/pages/pricing'));
// Reserve
const CustomCakePage = lazy(() => import('src/pages/cake/custom-details'));
const CheckoutPage = lazy(() => import('src/pages/cake/checkout'));
// Confirmation
const ConfirmationPage = lazy(() => import('src/pages/confirmation'));
// Custom Cake Gallery
const GalleryListPage = lazy(() => import('src/pages/gallery/list'));
// Order navigation page
const OrderNavPage = lazy(() => import('src/pages/order-nav'));
// Shop cake page
const ShopPage = lazy(() => import('src/pages/cake/list'));
// Cake details page
const CakeDetailsPage = lazy(() => import('src/pages/cake/details'));

// ----------------------------------------------------------------------

const RedirectToCake = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/cake');
  }, [navigate]);

  return null;
};

export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      // { path: 'about-us', element: <AboutPage /> },
      { path: '/', element: <HomePage /> },
      { path: 'contact-us', element: <ContactPage /> },
      { path: 'faqs', element: <FaqsPage /> },
      { path: 'pricing', element: <PricingPage /> },
      {
        path: 'gallery',
        children: [{ element: <GalleryListPage />, index: true }],
      },
      {
        path: 'order',
        element: <OrderNavPage />,
      },
      {
        path: 'cake',
        children: [
          { element: <ShopPage />, index: true },
          { path: 'custom-cake', element: <CustomCakePage /> },
          { path: ':nameUri', element: <CakeDetailsPage /> },
        ],
      },
      {
        path: 'reserve',
        children: [{ element: <RedirectToCake />, index: true }],
      },
      { path: 'checkout', element: <CheckoutPage /> },
      {
        path: 'confirmation',
        children: [
          { element: <Page404 />, index: true },
          { path: ':confirmationNumber', element: <ConfirmationPage /> },
        ],
      },
    ],
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
];
